import axios from 'axios';

// Puedes usar una variable de entorno para la URL base
const API_URL = process.env.REACT_APP_API_URL || 'http://192.168.18.105:8000/api';

const apiClient = axios.create({
  baseURL: API_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Agregar interceptores si es necesario
apiClient.interceptors.request.use(
  (config) => {
    // Puedes agregar un token de autorización aquí si es necesario
    const token = localStorage.getItem('authToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    // Manejo global de errores
    return Promise.reject(error);
  }
);

export default apiClient;
